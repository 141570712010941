.slider {
  position: relative;
  margin: 2rem 0;
  width: 100%;
}

.image {
  width: 100%;
  border-radius: 2px;
}

.icon {
  color: #ffb703;
  transition: color 0.3s;
}

.icon:hover {
  color: #fb8500;
}

.button {
  position: absolute;
  top: 45%;
  cursor: pointer;
  font-size: 0.8rem;
}

.left {
  left: 5%;
}

.right {
  right: 5%;
}

.caption {
  font-family: "Montserrat-Italic", "sans-serif";
  font-size: 1rem;
  margin-top: 5px;
}

/************
MEDIA QUERIES
*/

@media only screen and (max-width: 960px) {
  .button {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 800px) {
  .button {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 580px) {
  .slider {
    margin: 1rem 0;
  }

  .button {
    font-size: 0.5rem;
  }
}
