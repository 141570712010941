.title,
.subtitle {
  padding: 0 0.5rem;
  line-height: 1.5;
}

.title {
  margin-top: 4rem;
  font-family: "Montserrat-SemiBold", "sans-serif";
  font-size: 3rem;
  text-align: center;
}

.subtitle {
  margin-bottom: 2rem;
  font-family: "Montserrat-SemiBold", "sans-serif";
  font-size: 2.6rem;
  text-align: center;
}

.price {
  margin: 2rem 0;
  font-family: "Montserrat-Italic", "sans-serif";
  font-size: 2rem;
  text-align: center;
}

.image-container {
  display: flex;
}

.image {
  width: 100%;
  border-radius: 2px;
}

/************
MEDIA QUERIES
*/

@media only screen and (max-width: 800px) {
  .title {
    font-size: 2.6rem;
  }

  .subtitle {
    font-size: 2.2rem;
  }

  .price {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 500px) {
  .title,
  .subtitle {
    line-height: 1.2;
  }

  .title {
    font-size: 2.2rem;
  }

  .subtitle {
    font-size: 1.7rem;
  }
}
