.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.icon {
  width: 5rem;
  height: 5rem;
}

.title {
  margin-left: 1rem;
  font-family: "Montserrat-SemiBold", "sans-serif";
  font-size: 3rem;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1180px;
  margin: 0 2rem;
}

.image-container,
.text-container {
  margin: 2rem;
  width: 50%;
}

.image {
  width: 100%;
  border-radius: 2px;
}

.description {
  font-family: "Montserrat-Regular", "sans-serif";
  font-size: 1.5rem;
  line-height: 1.5;
}

/************
MEDIA QUERIES
*/

@media only screen and (max-width: 1085px) {
  .icon {
    width: 4.5rem;
    height: 4.5rem;
  }

  .title {
    font-size: 2.7rem;
  }

  .description {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 960px) {
  .icon {
    width: 4rem;
    height: 4rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .content {
    margin: 0;
    flex-direction: column;
  }

  .image-container,
  .text-container {
    width: 80%;
    margin: 1rem 0 3rem;
  }

  .description {
    margin: 0;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .icon {
    width: 3.3rem;
    height: 3.3rem;
  }

  .title {
    font-size: 2rem;
  }

  .image-container,
  .text-container {
    max-width: 80%;
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 580px) {
  .icon {
    width: 3rem;
    height: 3rem;
  }

  .title {
    font-size: 1.8rem;
  }

  .description {
    font-size: 1rem;
  }
}
