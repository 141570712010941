* {
  box-sizing: border-box;
}

body {
  color: #023047;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat-Italic";
  src: local("Montserrat-Italic"),
    url("./fonts/Montserrat-Italic.ttf") format("truetype");
  font-weight: normal;
}
