.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin: 0 5rem;
  padding: 2rem 0;
  font-family: "Montserrat-Regular", "sans-serif";
  font-size: 1rem;
  text-align: center;
  line-height: 1.5;
}

.button {
  font-family: "Montserrat-Regular", "sans-serif";
  font-size: 1rem;
  background-color: transparent;
  border: 1px solid transparent;
  color: #fb8500;
  transition: all 0.3s;
}

.button:first-child {
  margin-right: 1rem;
}

.button:hover {
  cursor: pointer;
  color: #ffb703;
  border-bottom: 1px solid #ffb703;
}

/************
MEDIA QUERIES
*/

@media only screen and (max-width: 800px) {
  .footer {
    flex-direction: column;
  }

  .copyright {
    margin-bottom: 1rem;
  }
}
