.main-content {
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    124deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(142, 202, 230, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );
}

@media only screen and (max-width: 800px) {
  .main-header {
    background-image: url(../../img/header-800w.jpg);
  }
}

@media only screen and (max-width: 500px) {
  .main-header {
    background-image: url(../../img/header-480w.jpg);
  }
}
