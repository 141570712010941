.header {
  margin-top: 4rem;
  height: 20vh;
  background-color: white;
  background-image: url(../../img/header-1199w.jpg);
  background-position: center top 20%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/*************
 MEDIA QUERIES
 ************/

@media only screen and (max-width: 800px) {
  .header {
    background-image: url(../../img/header-800w.jpg);
  }
}

@media only screen and (max-width: 500px) {
  .header {
    background-image: url(../../img/header-480w.jpg);
    margin-top: 0;
  }
}
