.navbar {
  width: 100%;
  height: 4rem;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  font-family: "Montserrat-Regular", "sans-serif";
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.nav-list {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-around;
}

a {
  transition: color 0.3s;
}

a:link,
a:visited {
  text-decoration: none;
  color: #fb8500;
}

a:hover,
a:active {
  color: #ffb703;
}

/************
MEDIA QUERIES
*/

@media only screen and (max-width: 800px) {
  .navbar {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 500px) {
  .navbar {
    background-color: transparent;
    height: 20vh;
    width: 100%;
    font-size: 1.1rem;
  }

  .nav-list {
    flex-direction: column;
    flex-grow: 0;
    align-items: stretch;
    justify-content: center;
  }

  .nav-item {
    background-color: rgba(255, 255, 255, 0.9);
    margin-bottom: 2px;
    padding: 0.5rem;
    text-align: center;
  }
}
