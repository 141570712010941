.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.icon {
  width: 5rem;
  height: 5rem;
}

.title {
  margin-left: 1rem;
  font-family: "Montserrat-SemiBold", "sans-serif";
  font-size: 3rem;
}

.subtitle {
  margin: 2rem;
  font-family: "Montserrat-Italic", "sans-serif";
  font-size: 2rem;
  line-height: 1.5;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
}

.image-container {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  width: 80%;
}

.text-container {
  margin: 1rem 2rem;
  width: 80%;
}

.highlight {
  font-family: "Montserrat-SemiBold", "sans-serif";
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.description {
  font-family: "Montserrat-Regular", "sans-serif";
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

/************
MEDIA QUERIES
*/

@media only screen and (max-width: 1085px) {
  .icon {
    width: 4.5rem;
    height: 4.5rem;
  }

  .title {
    font-size: 2.7rem;
  }

  .subtitle {
    font-size: 1.8rem;
  }

  .description {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 960px) {
  .icon {
    width: 4rem;
    height: 4rem;
  }

  .title {
    font-size: 2.5rem;
  }

  .subtitle-container {
    max-width: 80%;
  }

  .subtitle {
    font-size: 1.6rem;
    text-align: center;
  }

  .content {
    margin: 0;
  }

  .description {
    margin: 0 0 1rem;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 800px) {
  .icon {
    width: 3.3rem;
    height: 3.3rem;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 580px) {
  .icon {
    width: 3rem;
    height: 3rem;
  }

  .title {
    font-size: 1.8rem;
  }

  .subtitle-container {
    max-width: 100%;
    padding: 0 0.5rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }
}
