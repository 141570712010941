.title {
  margin: 2rem;
  font-family: "Montserrat-SemiBold", "sans-serif";
  font-size: 3rem;
  text-align: center;
  line-height: 1.5;
}

/************
MEDIA QUERIES
*/

@media only screen and (max-width: 800px) {
  .title {
    font-size: 2rem;
  }
}
